<template>
  <a-card :bordered="false">
    <div style="margin-bottom: 10px;">
      <a-row :gutter="48">
        <a-col :md="8" :sm="24">
          <span>
            <a-button @click="goAddAdminGroup()">添加管理组</a-button>
          </span>
        </a-col>
      </a-row>
    </div>

    <a-table :columns="columns" :dataSource="tableData" :loading="tableLoading" rowKey="id">
      <span slot="avatar" slot-scope="text">
        <img :src="text" height="25" style="border-radius: 50%;">
      </span>

      <span slot="status" slot-scope="text">
        {{ text | statusFilter }}
      </span>

      <span slot="last_login_time" slot-scope="text">
        {{ text | dayjs }}
      </span>

      <span slot="action" slot-scope="text, record">
        <a @click="goEditAdminGroup(record)">编辑</a>
        <a-divider type="vertical" />
        <a-popconfirm v-if="tableData.length" title="确定删除该节点?" @confirm="() => onDelete(record.id)">
          <a href="javascript:;">删除</a>
        </a-popconfirm>
      </span>
    </a-table>

  </a-card>
</template>

<script>
import { adminGroupList, adminGroupDelete } from '@/api/admin'

const columns = [
  {
    title: 'ID',
    dataIndex: 'id'
  },
  {
    title: '名称',
    dataIndex: 'title'
  },
  {
    title: '备注',
    dataIndex: 'desc'
  },
  {
    title: '状态',
    dataIndex: 'status',
    scopedSlots: { customRender: 'status' }
  },
  {
    title: '操作',
    width: '150px',
    scopedSlots: { customRender: 'action' }
  }]

export default {
  name: 'AdminGroup',
  data() {
    return {
      description: '列表使用场景：后台管理中的权限管理以及角色管理，可用于基于 RBAC 设计的角色权限控制，颗粒度细到每一个操作类型。',
      labelCol: {
        xs: { span: 24 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 }
      },
      // 高级搜索 展开/关闭
      advanced: false,
      // form
      form: this.$form.createForm(this),
      // 查询参数
      queryParam: {
        keywords: this.$route.query.keywords,
        status: (this.$route.query.status ? this.$route.query.status : '0')
      },
      columns,
      tableData: [],
      tableLoading: false
    }
  },
  filters: {
    statusFilter(status) {
      const statusMap = {
        1: '正常',
        2: '禁用'
      }
      return statusMap[status]
    }
  },
  created() {
    const parameter = this.queryParam
    this.loadData(parameter)
  },
  methods: {
    loadData(parameter) {
      this.tableLoading = true
      new Promise((resolve, reject) => {
        adminGroupList(parameter).then(response => {
          if (response.status === 'success') {
            resolve(response)
          } else {
            reject(response)
          }
        })
      }).then(res => {
        this.tableData = res.data
      }).catch(error => {
        this.$notification.error({
          message: error.status,
          description: error.message,
          duration: 4
        })
      }).finally(() => {
        this.tableLoading = false
      })
    },
    goAddAdminGroup() {
      this.$router.push('/config/admin/group-edit')
    },
    goEditAdminGroup(record) {
      this.$router.push({ path: '/config/admin/group-edit', query: { id: record.id } })
    },
    onDelete(id) {
      // 调用删除
      new Promise((resolve, reject) => {
        adminGroupDelete({ 'id': id }).then(response => {
          if (response.status === 'success') {
            resolve(response)
          } else {
            reject(response)
          }
        })
      }).then(res => {
        this.$notification.success({
          message: '成功',
          description: res.message
        })
        this.loadData()
      }).catch(error => {
        this.$notification.error({
          message: '错误',
          description: error.message
        })
      })
    }
  }
}
</script>
